import React, { useState } from 'react';
import Modal from '../../Utilities/Modal';
import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import { verifyOtp } from '../../actions/authAction';
import { toast } from 'react-toastify';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { verifyOTPForweddingQRLogin } from '../../actions/weddingQRActions';

const VerifyOtpModal = ({ onClose, sessionId, phone }) => {
  const location = useLocation();
  const [otp, setOtp] = useState('');
  const [isVerifying, setVerifying] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('sessionId ', sessionId, 'OTP', otp, 'Phone ', phone);

    try {
      setVerifying(true);
      const otp_data = { otp, session_id: sessionId, mobile: phone };
      const result = await verifyOTPForweddingQRLogin(otp_data);
      // console.log('result from verifyOtp ', result);
      setVerifying(false);
      if (result?.status) {
        navigate(`/weddingQR/Side/${location.search}`);
        onClose();
      } else {
        toast.error('Wrong OTP');
      }
    } catch (error) {
      console.log('Error in verifyOtp: ', error);
      toast.error('Error in getting info from server');
    }
  };

  return (
    <Modal toggle={onClose}>
      <Card>
        <CardBody>
          <h3>Please Enter OTP</h3>
          <form onSubmit={handleSubmit}>
            <InputGroup fullWidth>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </InputGroup>
            <Button type="submit" status="Success" shape="SemiRound" fullWidth disabled={isVerifying}>
              {isVerifying ? 'Verifying...' : 'Verify'}
            </Button>
          </form>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default VerifyOtpModal;
