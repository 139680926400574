import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';

export const isQRTokenValid = (token) => {
  if (!token) {
    return false;
  }
  try {
    const decodedToken = jwtDecode(token);
    console.log('decoded token ', decodedToken);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp >= currentTime && !decodedToken?.user?.user) {
      return true;
    }
    return false;
  } catch (error) {
    toast.error('Invalid token specified:', error);
    console.error('Invalid token specified:', error);
    return false;
  }
};
