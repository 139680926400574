import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import React, { useEffect, useState } from 'react';
import SEO from '../../components/SEO';
import { toast } from 'react-toastify';
import { useLocation } from '@reach/router';
import VerifyOtpModal from './VerifyOtpModal';
import { sendOTPForweddingQRLogin } from '../../actions/weddingQRActions';
import GroomBrideHeading from './GroomBrideHeading';
import groomBridePng from './Images/groomBridePng.png';
import './loginStyles.css';
import { isQRTokenValid } from '../../helpers/tokenUtils';
import { navigate } from 'gatsby';

export default function Login() {
  const [phone, setPhone] = useState('');
  const [isLoading, showLoader] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const brideName = searchParams.get('brideName');
  const groomName = searchParams.get('groomName');

  useEffect(() => {
    let token = null;
    if (localStorage.getItem('Token')) {
      token = localStorage.getItem('Token');
    }
    if (token && isQRTokenValid(token)) {
      navigate(`/weddingQR/Side/${location.search}`);
    }
  }, [location.search]);

  const onSubmit = async (e) => {
    e.preventDefault();
    showLoader(true);

    try {
      let mobile_obj = { mobile: phone };
      let result = await sendOTPForweddingQRLogin(mobile_obj);
      showLoader(false);
      if (result?.status) {
        setSessionId(result?.session_id);
        toast.success('OTP sent successfully');
        setShowOtpModal(true);
      } else {
        toast.error(result?.message);
      }
    } catch (error) {
      toast.error('Error in sending OTP');
      showLoader(false);
    }
  };

  return (
    <>
      {showOtpModal && <VerifyOtpModal onClose={() => setShowOtpModal(false)} sessionId={sessionId} phone={phone} />}
      <div className="LoginContainer">
        <SEO title="Login" />
        {/* <h1>Login</h1> */}
        <GroomBrideHeading brideName={brideName} groomName={groomName} />
        <img src={groomBridePng} alt="Groom and Bride" />
        <p>विवाह और अपनी तस्वीरें अपलोड करने के लिए लॉगिन करें</p>
        <form>
          <InputGroup fullWidth>
            <input type="text" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </InputGroup>
          <Button status="Success" type="button" shape="SemiRound" fullWidth onClick={onSubmit} disabled={isLoading}>
            {isLoading ? 'Sending OTP...' : 'Send OTP'}
          </Button>
        </form>
      </div>
    </>
  );
}
