import { storeToken } from './authAction';
import helpers from './commonActions';

const getApiWrapper = async (url) => {
  const Token = window.localStorage.getItem('Token') || null;
  if (Token) {
    const result = await helpers.httpGet(url, Token);
    return result;
  }
  throw new Error('Token not found');
};

const postApiWrapper = async (url, data) => {
  const Token = window.localStorage.getItem('Token') || null;
  if (Token) {
    const result = await helpers.httpPost(url, data, Token);
    return result;
  }
  throw new Error('Token not found');
};

export const postImageWeddingQr = async (data) => {
  const result = await postApiWrapper(`/api/wedding_events/independent_images`, data);
  return result;
};

export const getGroomBrideDetails = async (graph_node_id, side) => {
  const result = await getApiWrapper(`/api/wedding_events/find/${graph_node_id}/${side}`);
  return result;
};

export const getMobileUploadedImages = async (mobile, graph_node_id, side) => {
  const result = await getApiWrapper(
    `/api/wedding_events/independent_images?mobile=${mobile}&graph_node_id=${graph_node_id}&side=${side}`,
  );
  return result;
};

export const sendOTPForweddingQRLogin = async (data) => {
  const result = await helpers.httpPost(`/api/independent_mobile/login`, data, null);
  return result;
};

export const verifyOTPForweddingQRLogin = async (data) => {
  const json = await helpers.httpPost(`/api/independent_mobile/verify`, data, null);
  if (json.status) {
    console.log('result from otp verify ', json);
    // const { Token, user } = json;
    // const userData = { ...user, Token };
    storeToken(json);
  }
  return json;
};
export const getPresignedUrl = async (data) => {
  const result = await postApiWrapper(`/api/wedding_events/file_upload_url`, data);
  console.log('Result from getPresignedUrl', result);
  return result;
};
