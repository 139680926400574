import React, { useEffect, useState } from 'react';
import { nodeDetails } from '../../actions/nodeDetailsAction';
import './side.css';

const GroomBrideHeading = ({ brideName, groomName }) => {
  return (
    <h1 className="wedding-header">
      <span style={{ color: '#E8AA42' }}>आपका स्वागत है!</span>
      <br />
      <span style={{ color: '#DCA47C' }}>{groomName || 'GroomName'}</span>
      <span style={{ color: '#E8AA42' }}> और </span>
      <span style={{ color: '#DCA47C' }}>{brideName || 'BrideName'}</span>
      <br />
      <span style={{ color: '#E8AA42' }}>के विवाह में</span>
    </h1>
  );
};

export default GroomBrideHeading;
